import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink } from '../../../interfaces/navigation-item.interface';
import { dropdownAnimation } from '../../../animations/dropdown.animation';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';

import { LoginService } from 'src/app/pages/pages/auth/login/login.service';
import { ContratComponent } from 'src/app/pages/apps/contrat/contrat.component';
import { MiniErpComponent } from 'src/app/pages/apps/mini-erp/mini-erp.component';

@UntilDestroy()
@Component({
  selector: 'vex-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges {
  @ViewChild('contrat') ContratComponent: ContratComponent;
  @ViewChild('erp') MiniErpComponent: MiniErpComponent;
  ContractManagement: ContratComponent





  @Input() item: NavigationItem;
  @Input() level: number;
  @Input() contract: string
  isOpen: boolean;
  isActive: boolean;
  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  constructor(private router: Router, public loginService: LoginService,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
  ) { }
  hotel: boolean = false;
  contrat: boolean = false;
  erp: boolean = false
  profil: boolean = false
  event: boolean = false
  Package: boolean = false
  tour: boolean = false
  transfer: boolean = false
  xml_in: boolean = false
  xml_out: boolean = false
  Agence: boolean = false
  Booking: boolean = false
  Comparator: boolean = false
  configuration: boolean = false
  dashboard: boolean = false
  crm: boolean = false
  market: boolean = false
  permission: boolean = false
  response: any = false
  search: boolean = false
  stop_sale: boolean = false
  specialoffers: boolean = false
  log: boolean = false
  SuperAdmin: boolean = false
  user: boolean = false
  b2c:boolean = false
  payments:boolean = false
  @HostBinding('class')

  get levelClass() {
    return `item-level-${this.level}`;
  }

  ngOnInit() {
    this.response = JSON.parse(localStorage.getItem('reponse'))
    console.log("this.response: ", this.response)
    this.SuperAdmin = this.response.role == "superadmin"
    if (this.response != null) {
 

      if(this.response.role=='admin'){
        this.Agence = true
        this.loginService.setAgence(this.Agence)
        this.market = true
        this.loginService.setmarket(this.market)
        this.dashboard = true
        this.loginService.setdashboard(this.dashboard)
      }
      this.contrat = this.response.access.contrat
      this.loginService.setContrat(this.contrat)


      this.user = this.response.access.user
      this.loginService.setuser(this.user)
      this.b2c = this.response.access.b2c
      this.payments = this.response.access.payments

      this.log = this.response.access.log
      this.loginService.setlog(this.log)

      this.crm = this.response.access.crm
      this.loginService.setcrm(this.crm)

      this.erp = this.response.access.erp
      this.loginService.setErp(this.erp)

      this.hotel = this.response.access.hotel
        this.loginService.setHotel(this.hotel)

        this.event = this.response.access.event
        this.loginService.setEvent(this.event)
        this.Package = this.response.access.package
        this.loginService.setPackage(this.Package)




        this.tour = this.response.access.tour
        this.loginService.setTour(this.tour)

        this.transfer = this.response.access.transfer
        this.loginService.setTransfer(this.transfer)

        this.xml_in = this.response.access.xml_in
        this.loginService.setxmlin(this.xml_in)

        this.xml_out = this.response.access.xml_out
        this.loginService.setxmlout(this.xml_out)
   
    

        this.Booking = this.response.access.booking
        this.loginService.setBooking(this.Booking)

        this.Comparator = this.response.access.comparator
        this.loginService.setComparator(this.Comparator)

        this.configuration = this.response.access.configuration
        this.loginService.setconfiguration(this.configuration)

        this.permission = true
        this.loginService.setpermission(this.permission)

        this.search = this.response.access.search
        this.loginService.setsearch(this.search)

        this.stop_sale = this.response.access.stop_sale
        this.loginService.setstop_sale(this.stop_sale)

    }
    else {
      this.loginService.contrat$.subscribe((contrat) => {

        this.contrat = contrat;
        console.log("contrat***/", contrat)





      });

      this.loginService.contrat$.subscribe((erp) => {
        this.erp = erp
        console.log("erp***/", erp)
        if (erp == true) {
          this.MiniErpComponent


        }

      });

      this.loginService.permission$.subscribe((permission) => {
        this.permission = permission
        console.log("permission***/", permission)

      });
      this.loginService.stop_sale$.subscribe((stop_sale) => {
        this.stop_sale = stop_sale
        console.log("stop_sale***/", stop_sale)

      });

      this.loginService.search$.subscribe((search) => {
        this.search = search
        console.log("search***/", search)

      });
      this.loginService.contrat$.subscribe((hotel) => {
        this.hotel = hotel
        console.log("hotel***/", hotel)

      });
      this.loginService.dashboard$.subscribe((dashboard) => {
        this.dashboard = dashboard
        console.log("dashboard***/", dashboard)

      });
      this.loginService.crm$.subscribe((crm) => {
        this.crm = crm
        console.log("crm***/", crm)

      });

      this.loginService.event$.subscribe((event) => {
        this.event = event
        console.log("event***/", event)

      });

      this.loginService.Package$.subscribe((Package) => {
        this.Package = Package
        console.log("Package***/", Package)

      });
      this.loginService.configuration$.subscribe((configuration) => {
        this.configuration = configuration
        console.log("configuration***/", configuration)

      });

      this.loginService.Agence$.subscribe((Agence) => {
        this.Agence = Agence
        console.log("Agence***/", Agence)

      });
      this.loginService.Booking$.subscribe((Booking) => {
        this.Booking = Booking
        console.log("Booking***/", Booking)

      });
      this.loginService.Comparator$.subscribe((Comparator) => {
        this.Comparator = Comparator
        console.log("Comparator***/", Comparator)

      });

      this.loginService.profil$.subscribe((profil) => {
        this.profil = profil
        console.log("profil***/", profil)

      });

      this.loginService.transfer$.subscribe((transfer) => {
        this.transfer = transfer
        console.log("transfer***/", transfer)

      });

      this.loginService.tour$.subscribe((tour) => {
        this.tour = tour
        console.log("tour***/", tour)

      });


      this.loginService.xml_in$.subscribe((xml_in) => {
        this.xml_in = xml_in
        console.log("xml_in***/", xml_in)

      });


      this.loginService.xml_out$.subscribe((xml_out) => {
        this.xml_out = xml_out
        console.log("xml_out***/", xml_out)

      });


      this.loginService.tour$.subscribe((tour) => {
        this.tour = tour
        console.log("tour***/", tour)

      });
      this.loginService.market$.subscribe((market) => {
        this.market = market
        console.log("market***/", market)

      });
    }


    console.log("this.item: ", this.item)
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(item => this.onOpenChange(item));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some(child => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }
}
