<div class="toolbar  px-gutter flex items-center justify-between" [ngClass]="isConditionTrue ? 'w-full' : 'w-screen'">
  
  <!-- Left Section: Icons -->
  <div class="flex space-x-4 items-center ">
    <a *ngIf="!isConditionTrue" [routerLink]="['/apps/home']" class="flex-shrink-0 flex items-center justify-center px-4 lg:px-6 xl:px-8">
      <img class="h-16 w-32" [src]="logo" alt="" />
    </a>
    <a [routerLink]="['/apps/home']" class="lg:flex hidden w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/homeIcon.png" alt="" />
      <h3 class="text-xs text-center">Home</h3>
    </a>
    
    <div class="lg:flex hidden group w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/searchIcon.png" alt="" />
      <h3 class="text-xs text-center">Search</h3>

      <div class="group-hover:block hidden origin-center absolute mt-12 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <a [routerLink]="['/apps/searching']" class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Hotels</a>
          <a [routerLink]="['/apps/searching/Flights']" class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Flights</a>
          <a [routerLink]="['/apps/searching/tours']"   class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Tours</a>
          <a [routerLink]="['/apps/searching/transfert']"   class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Transfers</a>
          <a [routerLink]="['/apps/searching/package']"   class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Packages</a>

        </div>
      </div>
    </div>
    
    <a  [routerLink]="['/apps/reservations']" class=" lg:flex hidden w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/bookIcon.png" alt="" />
      <h3 class="text-xs text-center">Reservations</h3>
    </a>
    <!-- <a [routerLink]="['/apps/notFound']" class=" lg:flex hidden w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/reportsIcon.png" alt="" />
      <h3 class="text-xs text-center">Reports</h3>
    </a> -->
  
    <div class="lg:flex hidden group w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/userIcon.png" alt="" />
      <h3 class="text-xs text-center">Users</h3>

      <div class="group-hover:block  hidden origin-center absolute mt-12 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <a  [routerLink]="['/apps/group-user']" class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Group Users</a>
          <a  [routerLink]="['/apps/coopérateurs']" class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Users List</a>
          <a  [routerLink]="['/apps/permission-coopérateurs']" class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#6366f1]">Permission Users</a>
        </div>
      </div>
    </div>
    <a *ngIf="vue=='Rafahia'"[routerLink]="['/apps/contact_us']" class=" lg:flex hidden w-32 bg-white shadow-lg rounded-sm p-4 flex flex-col items-center h-16 hover:outline-none hover:ring-2 hover:ring-[#6366f1] hover:scale-105 focus:shadow-xl transition-all duration-300" tabindex="0">
      <img class="h-8 w-8" src="../../../assets/contactIcon.png" alt="" />
      <h3 class="text-xs text-center">Contact Us</h3>
    </a>
  </div>
  
  <!-- Right Section: Currency, Language, User -->
  <div class="flex items-center space-x-4 lg:flex hidden">
    <div class="px-1">
      <div style="padding: 10px; display: inline-block; font-size: 15px;">
        {{ convertedAmount | number:'1.2-2' }}
      </div>
      <button [matMenuTriggerFor]="DeviseMenu" mat-icon-button type="button">
        <span>{{DeviseCurrency}}</span>
      </button>
    </div>
    
    <mat-menu #DeviseMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeDevise('EUR')">
        <span>EUR</span>
      </button>
      <button mat-menu-item (click)="changeDevise('USD')">
        <span>USD</span>
      </button>
      <button mat-menu-item (click)="changeDevise('TND')">
        <span>TND</span>
      </button>
      <button mat-menu-item (click)="changeDevise('DZD')">
        <span>DZD</span>
      </button>
      <button mat-menu-item (click)="changeDevise('LYD')">
        <span>LYD</span>
      </button>
      <button mat-menu-item (click)="changeDevise('EGP')">
        <span>EGP</span>
      </button>
    </mat-menu>
    
    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon *ngIf="translate.currentLang==='en'"svgIcon="flag:united-states"></mat-icon>
        <mat-icon *ngIf="translate.currentLang==='fr'"svgIcon="flag:France"></mat-icon>
        <mat-icon *ngIf="translate.currentLang==='ar'"svgIcon="flag:Tunisia"></mat-icon>

      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
    
    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="changeLang('fr')">
        <mat-icon svgIcon="flag:France"></mat-icon>
        <span>Francais</span>
      </button>
      <button mat-menu-item (click)="changeLang('en')">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>
      <button mat-menu-item (click)="changeLang('ar')">
        <mat-icon svgIcon="flag:Tunisia"></mat-icon>
        <span>Arabe</span>
      </button>
    </mat-menu>
  </div>
  
  <!-- Hamburger Menu (Only visible on small screens, when the screen width is under 1332px) -->
  <div class="lg:hidden flex items-center">
    <button (click)="toggleMenu()" class="text-xl">
      <mat-icon svgIcon="mat:menu"></mat-icon>
    </button>
  </div>

  <!-- Collapsible Mobile Menu (Only visible on smaller screens, under 1332px) -->
  <div *ngIf="menuVisible" class="lg:hidden absolute top-0 right-0 w-64 bg-white shadow-lg p-4">
    <button (click)="menuVisible = false" class="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-3xl p-2 rounded-full">
      &times;
    </button>
    <ul>
      <li class="mb-4">
        <a href="apps/searching" class="block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/homeIcon.png" alt="" />
          <h3 class="text-xs">Home</h3>
        </a>
      </li>
      <li class="mb-4">
        <a href="apps/searching" class="block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/searchIcon.png" alt="" />
          <h3 class="text-xs">Search</h3>
        </a>
      </li>
      <li class="mb-4">
        <a href="apps/reservations" class="block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/bookIcon.png" alt="" />
          <h3 class="text-xs">Reservations</h3>
        </a>
      </li>
      <li class="mb-4">
        <a href="apps/notFound" class=" block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/reportsIcon.png" alt="" />
          <h3 class="text-xs">Reports</h3>
        </a>
      </li>
      <li class="mb-4">
        <a href="apps/coopérateurs" class="block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/userIcon.png" alt="" />
          <h3 class="text-xs">Users</h3>
        </a>
        
      </li>
      <li class="mb-4">
        <a href="apps/notFound" class="block text-center">
          <img class="h-8 w-8 mx-auto" src="../../../assets/contactIcon.png" alt="" />
          <h3 class="text-xs ">Contact Us</h3>
        </a>
        
      </li>
      <li class="mb-4">
        <div class="block text-center">
          {{ convertedAmount | number:'1.2-2' }}
        </div>
        <span class="block text-center" [matMenuTriggerFor]="DeviseMenu" mat-icon-button type="button">
          <span>{{DeviseCurrency}}</span>
        </span>
      </li>

 
      <li class="mb-4">
        <vex-toolbar-user></vex-toolbar-user>
      </li>
    </ul>
  </div>
</div>
