import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private address = new BehaviorSubject<any>({});
  addressData = this.address.asObservable();
  private currency = new BehaviorSubject<any>({});
  currentData = this.currency.asObservable();

  private soldeSubject = new BehaviorSubject<any>(localStorage.getItem('UserSD'));
  currentSolde = this.soldeSubject.asObservable();

  updateSOlde(solde: any) {
    console.log("solde***/",solde)
    this.soldeSubject.next(solde)
    localStorage.setItem('UserSD', solde);

  
  }
  private DeviseCurrency = new BehaviorSubject<any>(localStorage.getItem('Currencyyy'));
  currentDataDevise = this.DeviseCurrency.asObservable();


 

  updateCurrency(DeviseCurrency: any) {
    console.log("DeviseCurrency***/",DeviseCurrency)
    this.DeviseCurrency.next(DeviseCurrency)
    


  }

  private role = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('reponserole')));
  currentrole = this.role.asObservable();

  updateRole(role: any) {
    console.log("role",role)
    this.role.next(role)

  
  }

  // contrat access
  private contratSubject = new BehaviorSubject<boolean>(true);
  contrat$ = this.contratSubject.asObservable();
  
  //erp access
  private erpSubject = new BehaviorSubject<boolean>(true);
  erp$ = this.erpSubject.asObservable();
 //log access
 private logSubject = new BehaviorSubject<boolean>(true);
 log$ = this.logSubject.asObservable();

  //hotel access
  private hotelSubject = new BehaviorSubject<boolean>(true);
  hotel$ = this.hotelSubject.asObservable();

  //event access
  private eventSubject = new BehaviorSubject<boolean>(true);
  event$ = this.eventSubject.asObservable();

  // package acess
  private packageSubject = new BehaviorSubject<boolean>(true);
  Package$ = this.packageSubject.asObservable();

  //profil access
  private profilSubject = new BehaviorSubject<boolean>(true);
  profil$ = this.profilSubject.asObservable();

  //access tour
  private tourSubject = new BehaviorSubject<boolean>(true);
  tour$ = this.tourSubject.asObservable();

  //access transfer
  private transferSubject = new BehaviorSubject<boolean>(true);
  transfer$ = this.transferSubject.asObservable();

  //accessxml_in
  private xml_inSubject = new BehaviorSubject<boolean>(true);
  xml_in$ = this.xml_inSubject.asObservable();

  //acessxml_out
  private xml_outSubject = new BehaviorSubject<boolean>(true);
  xml_out$ = this.xml_outSubject.asObservable();

  //agence
  private AgenceSubject = new BehaviorSubject<boolean>(true)
  Agence$ = this.AgenceSubject.asObservable();

  //booking
  private BookingSubject = new BehaviorSubject<boolean>(true)
  Booking$ = this.BookingSubject.asObservable();

  //comparator
  private ComparatorSubject = new BehaviorSubject<boolean>(true)
  Comparator$ = this.ComparatorSubject.asObservable();

  //configuration
  private configurationSubject = new BehaviorSubject<boolean>(true)
  configuration$ = this.configurationSubject.asObservable();

  //crm
  private crmSubject = new BehaviorSubject<boolean>(true)
  crm$ = this.crmSubject.asObservable();

  //market
  private marketSubject = new BehaviorSubject<boolean>(true)
  market$ = this.marketSubject.asObservable();
  
  //market
  private userSubject = new BehaviorSubject<boolean>(true)
  user$ = this.userSubject.asObservable();
  //dashboard
  private dashboardSubject = new BehaviorSubject<boolean>(true)
  dashboard$ = this.dashboardSubject.asObservable();

  //permission
  private permissionSubject = new BehaviorSubject<boolean>(true)
  permission$ = this.permissionSubject.asObservable();

  //search
  private searchSubject = new BehaviorSubject<boolean>(true)
  search$ = this.searchSubject.asObservable();

  //stop_sale
  private stop_saleSubject = new BehaviorSubject<boolean>(true)
  stop_sale$ = this.stop_saleSubject.asObservable();
  private idagence = new BehaviorSubject<any>({});
  idagenceData = this.idagence.asObservable();
  currentDataagency = this.idagence.asObservable();

  setstop_sale(stop_sale: boolean): void {
    this.stop_saleSubject.next(stop_sale);
  }
  setpermission(permission: boolean): void {
    this.permissionSubject.next(permission);
  }
  setsearch(search: boolean): void {
    this.searchSubject.next(search);
  }
 

  setlog(log: boolean): void {
    this.logSubject.next(log);
  }
  /****************************************************************** */
  setTour(tour: boolean): void {
    this.tourSubject.next(tour);
  }
  setErp(erp: boolean): void {
    this.erpSubject.next(erp);
  }

  setHotel(hotel: boolean): void {
    this.hotelSubject.next(hotel);
  }

  setEvent(event: boolean): void {
    this.eventSubject.next(event);
  }

  setPackage(Package: boolean): void {
    this.packageSubject.next(Package);
  }
  /********************************************************************** */
  setContrat(contrat: boolean): void {
    this.contratSubject.next(contrat);
  }
  setmarket(market: boolean): void {
    this.marketSubject.next(market);
  }
  setuser(user: boolean): void {
    this.userSubject.next(user);
  }
  setdashboard(dashboard: boolean): void {
    this.dashboardSubject.next(dashboard);
  }
  setcrm(crm: boolean): void {
    this.crmSubject.next(crm);
  }
 

  setProfil(profil: boolean): void {
    this.profilSubject.next(profil);
    this.profilSubject.next(true);
  }

 


  setTransfer(transfer: boolean): void {
    this.transferSubject.next(transfer);
  }

  setxmlin(xml_in: boolean): void {
    this.xml_inSubject.next(xml_in);
  }

  setxmlout(xml_out: boolean): void {
    this.xml_outSubject.next(xml_out);
  }
  setAgence(agence: boolean): void {
    this.AgenceSubject.next(agence);
  }
  setBooking(booking: boolean): void {
    this.BookingSubject.next(booking);
  }
  setComparator(Comparator: boolean): void {
    this.ComparatorSubject.next(Comparator);
  }
  setconfiguration(configuration: boolean): void {
    this.configurationSubject.next(configuration);
  }
  
  signIncallBack() {
    throw new Error('Method not implemented.');
  }

  urlLogin: string
  urlGetAccess: string
  constructor(private http: HttpClient) {
    this.urlLogin = environment.urlLogin
    this.urlGetAccess = environment.urlGetAccess

    

  }
  send(credentials) {
    return this.http.post<any>(this.urlLogin, credentials);
  }
  GetAccess() {
    return this.http.get<any>(this.urlGetAccess);
  }
  updatedata(currency: any) {
    this.currency.next(currency)
  }

updatedataagence(idagence: any) {
  this.idagence.next(idagence)
}
updatedataAddress(address: any) {
  this.address.next(address)
}
updatedataagency(idagence: any) {
  this.idagence.next(idagence)
}

updaterole(role: any) {
  this.role.next(role)
}


}