<div 
     [class.collapsed]="collapsed"
     [class.open]="false"
     class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <button (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 -mr-2 leading-none flex-none  lg:block"
      mat-icon-button
      type="button">
<mat-icon *ngIf="!collapsed"
         
          svgIcon="mat:menu"></mat-icon>
<mat-icon *ngIf="collapsed"
         
          svgIcon="mat:menu"></mat-icon>
</button>
      <img *ngIf="!collapsed && vue=='Dotcom'" [src]="image" style="
      max-width: 125%;
      height: 312%;
      margin-bottom: -29px;
      "/>
<img *ngIf="!collapsed && vue=='Rafahia'" [src]="image" style="
padding-left: 20%;
padding-top: 15%;
height: 250%;
"/>
 

    </div>

  </div>

  <vex-scrollbar class="flex-1 flex flex-col" style="margin-top: 28px;"  >
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>


  </vex-scrollbar>

  <div *ngIf="userVisible$ | async"
       class="vex-sidenav-user__container flex-none">
    <div #userProfileMenuOriginRef
         (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.vex-sidenav-user--open]="userMenuOpen$ | async"
         class="vex-sidenav-user flex items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">

    </div>
  </div>
</div>

