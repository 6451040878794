import { Inject, Injectable } from "@angular/core";
import { WINDOW } from "@ng-web-apis/common";
import jwt_decode from 'jwt-decode'
export let jwtToken: string;

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {

    decodedToken: { [key: string]: string };

    constructor(@Inject(WINDOW) private windowRef: Window) {
    }

    
    clear() {
      this.windowRef.localStorage.clear();
      jwtToken = null
      this.windowRef.localStorage.clear()
    }

    getToken(){
     
      jwtToken = jwtToken? jwtToken : this.windowRef.localStorage.getItem("ACCESS_TOKEN_KEY") ? this.windowRef.localStorage.getItem("ACCESS_TOKEN_KEY") : null
      return jwtToken
    }
    setToken(token: string) {
      if (token) {
        jwtToken = token;
        this.windowRef.localStorage.removeItem("ACCESS_TOKEN_KEY");
        this.windowRef.localStorage.setItem("ACCESS_TOKEN_KEY", token);
      }
    }

    decodeToken() {
      if (jwtToken) {
      this.decodedToken = jwt_decode(jwtToken);

      }
    }

    getDecodeToken() {
      return jwt_decode(jwtToken);
    }

    getUser() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken.Login : null;
    }
    getUserId() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken.user_id : null;
    }
    getEmailId() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken.email : null;
    }

    getExpiryTime() {
      this.decodeToken();
      return this.decodedToken ? this.decodedToken.exp : null;
    }

    isTokenExpired(): boolean {
      const expiryTime = parseInt(this.getExpiryTime(), 10);  
      if (!isNaN(expiryTime) && expiryTime > 0) {
        const currentTime = new Date().getTime();
        const tokenExpiryTime = 1000 * expiryTime; 
        return (tokenExpiryTime - currentTime) < 5000;
      } else {
        return true; 
      }
    }
}